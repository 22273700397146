import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import AuthContext from './AuthContext';

const Header = () => {
  const { authUser } = useContext(AuthContext);

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography className='loginButton' variant="h6" component={Link} to="/" sx={{ flexGrow: 1 }}>
          Your App Name
        </Typography>
        {authUser ? (
          <Button className='loginButton' color="inherit" component={Link} to="/profile">
            Profile
          </Button>
        ) : (
          <Button className='loginButton' color="inherit" component={Link} to="/login">
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;

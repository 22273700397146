// src/components/Background.js

import React from 'react';
import Conversation from '../components/Conversation.jsx'

const Background = () => {
  return (
    <div className='background'>
      <Conversation/>
    </div>
  );
};

export default Background;

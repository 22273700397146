import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import LoginSignupPage from './components/LoginSignUpPage';
import HomePage from './pages/HomePage';
import Profile from './components/Profile';
import './App.css';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);

  const handleLoginSuccess = (response) => {
    setIsLoggedIn(true);
    setUserData(response.profileObj);
    // Perform any additional actions on successful login
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserData(null);
    // Perform any additional actions on logout
  };

  return (
    <Router>
      <div>
        <Header isLoggedIn={isLoggedIn} userData={userData} onLogout={handleLogout} />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginSignupPage onLoginSuccess={handleLoginSuccess} />} /> 
          <Route path="/profile" element={<Profile userData={userData} />} />
          {/* Add other routes here */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;

import React, { useContext } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Use named import
import AuthContext from './AuthContext';
import axios from 'axios'; 

const LoginSignupPage = () => {
  const { setAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLoginSuccess = async (response) => {
    try {
      const decoded = jwtDecode(response.credential);
      const { email, name } = decoded;

      // Send token to backend for verification and get user data
      const res = await axios.post('/api/google-login/', { token: response.credential });

      const user = {
        email: res.data.email,
        name: res.data.name,
        created_at: res.data.created_at,
      };

      setAuthUser(user);
      navigate('/');
    } catch (error) {
      console.error('Login Failed:', error);
    }
}

  const handleLoginFailure = (error) => {
    console.log('Login Failed:', error);
  };

  return (
    <div>
      <h1>Login</h1>
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onError={handleLoginFailure}
      />
    </div>
  );
};

export default LoginSignupPage;

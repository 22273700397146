import React, { useState, useEffect, useRef } from 'react';
import { Typography, TextField, Button, Paper} from '@mui/material';

const Conversation = () => {
  const [inputValue, setInputValue] = useState('');
  const [conversation, setConversation] = useState([]);
  const [csrfToken, setCsrfToken] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const conversationRef = useRef(null);

  useEffect(() => {
    const getCsrfToken = async () => {
      const response = await fetch("/api/get_csrf_token/");
      if (response.ok) {
        const data = await response.json();
        setCsrfToken(data.csrf_token);
      } else {
        console.error('Failed to fetch CSRF token');
      }
    };
    getCsrfToken();
  }, []);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleClearFile = () => {
    setSelectedFile(null);
    const fileInput = document.getElementById('file-input');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedFile) {
      const formData = new FormData();
      formData.append('image', selectedFile);
      formData.append('text', inputValue);

      try {
        const response = await fetch("/api/ask_nyc_image/", {
          method: 'POST',
          headers: {
            'X-CSRFToken': getCookie('csrftoken'),
          },
          body: formData,
        });
        if (response.ok) {
          const data = await response.json();
          setConversation([...conversation, { input: 'Image uploaded', output: data.output }]);
          setSelectedFile(null);
          setInputValue('');
        } else {
          console.error('Failed to send data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      try {
        const response = await fetch("/api/ask_new_york/", {
          method: 'POST',
          headers: {
            'X-CSRFToken': getCookie('csrftoken'),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ input_value: inputValue }),
        });
        if (response.ok) {
          const data = await response.json();
          setConversation([...conversation, { input: inputValue, output: data.output }]);
          setInputValue('');
        } else {
          console.error('Failed to send data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  useEffect(() => {
    conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    //conversationRef.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
  }, [conversation]);

  return (
    <div className="inputAndOutput">
      <div className="outputSection">
        <Paper elevation={3} className="conversation" ref={conversationRef}>
          {conversation.map((entry, index) => (
            <div key={index}>
              <Typography variant="body1"><strong>User:</strong> {entry.input}</Typography>
              {entry.output && entry.output.startsWith('data:image') ? (
                <img src={entry.output} alt="Uploaded" className="uploaded-image" />
              ) : (
                <Typography variant="body1"><strong>AskNYC:</strong> {entry.output}</Typography>
              )}
              {index !== conversation.length - 1 && <hr className="separator" />}
            </div>
          ))}
        </Paper>
      </div>

      <div className="inputSection">
        <div className="fileInput">
          <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              id="file-input"
              style={{ display: 'none' }}
            />
            <Button variant="outlined" className="fileButton" onClick={() => document.getElementById('file-input').click()} >
              Upload Picture
            </Button>
            {selectedFile && (
              <Button variant="outlined" color="secondary" onClick={handleClearFile}>
                Clear
              </Button>
            )}
        </div>
        <div className="textInput">
          <TextField
            label="AskNYC"
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            InputLabelProps={{ className: 'inputLabel' }}
          />
        </div>
        <div className="buttonArea">
          <form onSubmit={handleSubmit}>
            <Button type="submit" variant="contained" color="primary">Submit</Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Conversation;
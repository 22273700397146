import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';

const ProfilePage = () => {
  const { authUser, setAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    setAuthUser(null);
    localStorage.removeItem('authUser');
    navigate('/login');
  };

  return (
    <div>
      <h1>Profile</h1>
      {authUser ? (
        <>
          <p>Email: {authUser.email}</p>
          <p>Account Created: {new Date(authUser.created_at).toLocaleDateString()}</p>
          <Button variant="contained" color="primary" onClick={handleLogout}>
            Logout
          </Button>
        </>
      ) : (
        <p>No user is logged in.</p>
      )}
    </div>
  );
};

export default ProfilePage;
